import { Box, Stack, Button } from '@mui/material';
import { useAuthenticated } from 'react-admin';
import Logo from '../../../assets/images/ioet.png';
import AuthConfig from '../../../auth/config';

const LoginPage = () => {
  useAuthenticated();

  return (
    <Stack
      direction='column'
      justifyContent='center'
      alignItems='center'
      sx={{
        height: '100vh',
      }}
    >
      <Box component='img' alt='ioet Logo' src={Logo} />
      <Button variant='contained' href={AuthConfig.authServiceLoginURL}>
        Login
      </Button>
    </Stack>
  );
};

export default LoginPage;
