import { Admin, CustomRoutes, Resource } from 'react-admin';
import { Route } from 'react-router-dom';

import Layout from './presentation/templates/Layout';
import Landing from './presentation/pages/Landing';
import LoginPage from './presentation/pages/Login';
import NotFound from './presentation/pages/Messages/NotFound';
import ManualReport from './presentation/pages/ManualReport';
import RatesReport from './presentation/pages/RatesReport';
import EditRates from './presentation/pages/EditRates';

import { billinglightTheme } from './AppTheme';
import AuthProvider from './auth/provider';
import dataCustomProvider from './business_logic/repositories/dataProvider';
import RoleBaseGuard from './auth/guard/RoleBaseGuard';
import { ROLES } from './utils/types';

const App = () => (
  <Admin
    layout={Layout}
    theme={billinglightTheme}
    loginPage={LoginPage}
    authProvider={AuthProvider}
    dataProvider={dataCustomProvider}
    catchAll={NotFound}
    requireAuth
  >
    <CustomRoutes>
      <Route
        path=''
        element={(
          <RoleBaseGuard allowedRoles={[ROLES.reporter]}>
            <Landing />
          </RoleBaseGuard>
        )}
      />
      <Route
        path='/manualReport'
        element={(
          <RoleBaseGuard allowedRoles={[ROLES.reporter]}>
            <ManualReport />
          </RoleBaseGuard>
        )}
      />
      <Route
        path='/ratesReport'
        element={(
          <RoleBaseGuard allowedRoles={[ROLES.viewer, ROLES.fixedRatesEditor]}>
            <RatesReport />
          </RoleBaseGuard>
        )}
      />
      <Route
        path='/editRates'
        element={(
          <RoleBaseGuard>
            <EditRates />
          </RoleBaseGuard>
        )}
      />
      <Route path='*' element={<NotFound />} />
    </CustomRoutes>

    <Resource name='' />
  </Admin>
);

export default App;
