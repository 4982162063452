import { defaultTheme } from 'react-admin';

export enum AppColors {
  main = '#EB505D',
  darkMain = '#f3495a',
  secondary = '#E9623F',
  hover = '#FCEAEB',
  white = '#FFFFFF',
  black = '#000000',
  grey = '#d7d7d7',
  dark = '#121212',
}

const customePalette = {
  primary: {
    main: AppColors.main,
    dark: AppColors.darkMain,
  },
  secondary: {
    main: AppColors.secondary,
    contrastText: AppColors.white,
  },
};

export const billingdarkTheme = {
  palette: {
    ...customePalette,
    background: {
      default: AppColors.dark,
    },
    mode: 'dark' as const,
  },
  shape: {
    borderRadius: 5,
  },
  sidebar: {
    width: 250,
    minWidth: 50,
  },
  components: {
    ...defaultTheme.components,
    RaMenuItemLink: {
      styleOverrides: {
        root: {
          color: AppColors.white,
          backgroundColor: AppColors.dark,
          '&.RaMenuItemLink-active': {
            color: AppColors.main,
            backgroundColor: AppColors.hover,
            '& .RaMenuItemLink-icon': {
              color: AppColors.main,
            },
          },
          '&:hover': {
            color: AppColors.main,
            backgroundColor: AppColors.hover,
            '& .RaMenuItemLink-icon': {
              color: AppColors.main,
            },
          },
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        elevation1: {
          boxShadow: 'none',
        },
      },
    },
    MuiAppBar: {
      styleOverrides: {
        colorSecondary: {
          color: AppColors.white,
          backgroundColor: AppColors.black,
        },
      },
    },
  },
};

export const billinglightTheme = {
  palette: {
    ...customePalette,
    background: {
      default: AppColors.white,
    },
    mode: 'light' as const,
  },
  shape: {
    borderRadius: 5,
  },
  sidebar: {
    width: 200,
    minWidth: 60,
  },
  components: {
    ...defaultTheme.components,
    RaMenuItemLink: {
      styleOverrides: {
        root: {
          color: AppColors.black,
          backgroundColor: AppColors.white,
          '&.RaMenuItemLink-active': {
            color: AppColors.main,
            backgroundColor: AppColors.hover,
            '& .RaMenuItemLink-icon': {
              color: AppColors.main,
            },
          },

          '&:hover': {
            color: AppColors.main,
            backgroundColor: AppColors.hover,
            '& .RaMenuItemLink-icon': {
              color: AppColors.main,
            },
          },
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        elevation1: {
          boxShadow: 'none',
        },
      },
    },
    MuiAppBar: {
      styleOverrides: {
        colorSecondary: {
          color: AppColors.white,
          backgroundColor: AppColors.white,
        },
      },
    },
    RaDatagrid: {
      styleOverrides: {
        root: {
          '& .MuiTableCell-head': {
            backgroundColor: AppColors.main,
            color: AppColors.white,
            fontWeight: 'bold',
          },
          '& .MuiTableRow-root.MuiTableRow-hover:hover': {
            backgroundColor: AppColors.hover,
          },
        },
      },
    },
  },
};
