import { Datagrid, TextField } from 'react-admin';

const ListInvoice = () => (
  <Datagrid bulkActionButtons={false}>
    <TextField source='id' label='Id' />
    <TextField source='client' label='Client' />
    <TextField source='invoice_number' label='Invoice Number' />
    <TextField source='project_name' label='Project Name' />
    <TextField source='name' label='Name' />
    <TextField source='role' label='Role' />
    <TextField source='level' label='Level' />
    <TextField source='quantifier' label='Quantifier' />
    <TextField source='start_date' label='Start Date' />
    <TextField source='end_date' label='End Date' />
  </Datagrid>
);

export default ListInvoice;
