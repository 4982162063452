import React, { useState } from 'react';
import { Stack, Typography, Button } from '@mui/material';
import Alert from '@mui/material/Alert';
import CircularProgress from '@mui/material/CircularProgress';
import Snackbar from '@mui/material/Snackbar';
import Dropzone from '../../molecules/Dropzone';

import useDownloadFile from '../../../hooks/useDownloadFile';
import FileList from './FileList';
import DatePicker from '../../molecules/DatePicker';
import formatDate from '../../../business_logic/utils/formatDate';
import Config from '../../../utils/config';

const centerPageStyle = {
  paddingTop: '64px',
  justifyContent: 'center',
  alignItems: 'center',
  height: '-webkit-fill-available',
  width: '100%',
};

const ManualReport = () => {
  const [error, setError] = useState('');
  const [notionFile, setNotionFile] = useState<File>();
  const [bambooFile, setBambooFile] = useState<File>();
  const [EYfile, setEYfile] = useState<File>();
  const [date, setDate] = useState<Date>(new Date());
  const { getManualFile, loading } = useDownloadFile();

  const { numberOfFiles } = Config;

  const downloadReport = async () => {
    const [month, year] = formatDate(date).split('-').slice(1);
    const url = `/manual-report/${year}/${Number(month)}`;
    const fileName = `report_${year}_${month}.xlsx`;

    if (notionFile && bambooFile && EYfile) {
      try {
        await getManualFile(url, fileName, notionFile, bambooFile, EYfile);
      } catch (err) {
        let message = 'Unknown Error';
        if (err instanceof Error) message = err.message;
        setError(message);
      }
    } else {
      setError('Please, first select the files for downloading the report.');
    }
  };

  const onChange = (newDate: Date | null) => {
    if (newDate) {
      setDate(newDate);
    }
  };

  return (
    <Stack sx={centerPageStyle} spacing={4}>
      <Typography variant='h3'>Manual Report</Typography>
      <Stack
        justifyContent='center'
        alignItems='center'
        spacing={4}
        sx={{
          maxWidth: '600px',
          width: '60%',
        }}
      >
        <Stack
          direction='row'
          spacing={2}
          justifyContent='center'
          alignItems='center'
        >
          <Typography variant='subtitle1'>Select Date:</Typography>
          <DatePicker date={date} handleChange={onChange} />
        </Stack>
        <Stack direction='row' spacing={6}>
          <Stack spacing={2}>
            <Dropzone
              title='Notion file'
              maxFiles={numberOfFiles}
              error={setError}
              file={setNotionFile}
            />
            {notionFile ? <FileList files={[notionFile]} /> : null}
          </Stack>
          <Stack spacing={2}>
            <Dropzone
              title='Bamboo file'
              maxFiles={numberOfFiles}
              error={setError}
              file={setBambooFile}
            />
            {bambooFile ? <FileList files={[bambooFile]} /> : null}
          </Stack>
          <Stack spacing={2}>
            <Dropzone
              title="EY's report file"
              maxFiles={numberOfFiles}
              error={setError}
              file={setEYfile}
            />
            {EYfile ? <FileList files={[EYfile]} /> : null}
          </Stack>
        </Stack>
        <Button
          variant='contained'
          sx={{ width: '250px' }}
          onClick={downloadReport}
        >
          {loading ? (
            <CircularProgress color='inherit' size={24} />
          ) : (
            'DOWNLOAD'
          )}
        </Button>
      </Stack>
      <Snackbar
        open={error !== ''}
        autoHideDuration={4000}
        onClose={() => setError('')}
      >
        <Alert variant='filled' severity='error'>
          {error}
        </Alert>
      </Snackbar>
    </Stack>
  );
};

export default ManualReport;
