import { Box, TextField } from '@mui/material';
import Grid from '@mui/material/Grid';
import React, { useState } from 'react';
import { EditRateForm, UseForm } from '../../../utils/types';

const EditFixRateForm: React.FC<EditFixRateFormProps> = ({
  formValues,
  handleInputChange,
}) => {
  const { fixRate } = formValues;
  const [isFixRateValid, setIsFixRateValid] = useState(true);

  const onChangeFixedRateInput = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    setIsFixRateValid(
      Boolean(event.target.value)
        && !Number.isNaN(event.target.value)
        && parseFloat(event.target.value) > 0,
    );

    handleInputChange({
      target: {
        name: 'fixRate',
        value: parseFloat(event.target.value),
      },
    });
  };

  return (
    <div style={{ margin: '20px 0' }}>
      <Box sx={{ flexGrow: 1 }}>
        <Grid
          container
          item
          spacing={2}
          direction='column'
          alignItems='center'
          justifyContent='space-between'
        >
          <Grid container item spacing={2} direction='row'>
            <Grid item xs={12}>
              <Box display='flex'>
                <TextField
                  fullWidth
                  label='Fix Rate:'
                  placeholder='Enter new fix rate'
                  required
                  onChange={onChangeFixedRateInput}
                  type='number'
                  error={!isFixRateValid}
                  helperText={
                    !isFixRateValid
                    && 'Enter a valid fix rate (only positive numbers are allowed)'
                  }
                  name='fixRate'
                  value={!Number.isNaN(fixRate) ? fixRate : ''}
                />
              </Box>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </div>
  );
};

export default EditFixRateForm;

type EditFixRateFormProps = {
  handleInputChange: UseForm<EditRateForm>['handleInputChange'];
  formValues: Partial<EditRateForm>;
};
