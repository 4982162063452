import { AuthProvider as authProviderRRA } from 'react-admin';
import authService from './service';
import AuthConfig from './config';

const AuthProvider: authProviderRRA = {
  login: async () => {},
  logout: async () => {
    try {
      localStorage.removeItem(AuthConfig.storageUserKey);
      await authService.logout();
    } catch (error) {
      // comment this line for local development
      return Promise.reject();
    }
    return Promise.resolve();
  },
  checkAuth: async () => {
    try {
      await authService.getLoggedUser();
      const { loginPath } = AuthConfig;
      if (
        window.location.hash.includes(loginPath)
        || window.location.pathname.includes(loginPath)
      ) {
        window.location.replace('/');
      }
      return await Promise.resolve();
    } catch (error) {
      return Promise.reject();
    }
  },
  checkError: (error) => {
    const { status } = error;
    if (status === 401 || status === 403) {
      // eslint-disable-next-line prefer-promise-reject-errors
      return Promise.reject({ logoutUser: false });
    }
    return Promise.resolve();
  },
  getPermissions: async () => {
    try {
      return await authService.getUserPermissions();
    } catch (e) {
      return { roles: [], featureToggles: [] };
    }
  },
  getIdentity: async () => {
    const storedUser = localStorage.getItem(AuthConfig.storageUserKey);

    if (storedUser) {
      return JSON.parse(storedUser);
    }

    try {
      const { data: currentUser } = await authService.getLoggedUser();
      const userToStorage = {
        id: currentUser?.sub,
        fullName: currentUser?.name,
        avatar: currentUser?.picture,
      };
      localStorage.setItem(
        AuthConfig.storageUserKey,
        JSON.stringify(userToStorage),
      );
      return userToStorage;
    } catch (e) {
      return Promise.reject();
    }
  },
};

export default AuthProvider;
