import {
  DataProvider,
  GetOneResult,
  GetListParams,
  GetOneParams,
  GetManyParams,
  GetManyReferenceParams,
  CreateParams,
  UpdateParams,
  UpdateManyParams,
  DeleteParams,
  DeleteManyResult,
} from 'react-admin';

import InvoiceProvider from './invoice';

const resultOne: GetOneResult = { data: { id: 1, name: '' } }; // Todo: fix response

const deleteManyList: DeleteManyResult = {
  // Todo: fix response
  data: [1, 2],
};

const emptyListResponse = {
  data: [],
  total: 0,
};

/* eslint-disable */
const dataCustomProvider: DataProvider = {
  getList: async (resource: string, params: GetListParams) => {
    try {
      return await InvoiceProvider.getList(resource, params);
    } catch (error) {
      return emptyListResponse;
    }
  },
  getOne: (resource: string, params: GetOneParams) =>
    Promise.resolve(resultOne),
  getMany: async (resource: string, params: GetManyParams) => {
    const response = await InvoiceProvider.getList(resource, params);
    return response;
  },
  getManyReference: async (
    resource: string,
    params: GetManyReferenceParams,
  ) => {
    const response = await InvoiceProvider.getList(resource, params);
    return response;
  },
  create: (resource: string, params: CreateParams) =>
    Promise.resolve(resultOne),
  update: (resource: string, params: UpdateParams) =>
    Promise.resolve(resultOne),
  updateMany: async (resource: string, params: UpdateManyParams) => {
    const response = await InvoiceProvider.getList(resource, params);
    return response;
  },
  delete: (resource: string, params: DeleteParams) =>
    Promise.resolve(resultOne),
  deleteMany: (resource, params) => Promise.resolve(deleteManyList),
};

export default dataCustomProvider;
