import { UserMenu, Logout, useGetIdentity } from 'react-admin';
import LogoutIcon from '@mui/icons-material/Logout';
import { CircularProgress } from '@mui/material';
import CustomAvatar from '../Avatar';

const CustomUserMenu = () => {
  const { identity, isLoading: identityLoading } = useGetIdentity();
  return identityLoading ? (
    <CircularProgress color='inherit' size={24} />
  ) : (
    <UserMenu
      icon={
        <CustomAvatar imgUrl={identity?.picture ? identity?.picture : ''} />
      }
    >
      <Logout icon={<LogoutIcon />} />
    </UserMenu>
  );
};

export default CustomUserMenu;
