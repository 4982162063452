import { AppBar, AppBarProps, useGetIdentity } from 'react-admin';
import {
  Box,
  useMediaQuery,
  Theme,
  Typography,
  Stack,
  CircularProgress,
} from '@mui/material';
import Logo from '../../../assets/icons/BA_logo.svg';
import { AppColors } from '../../../AppTheme';
import CustomUserMenu from '../UserMenu';

const CustomAppBar = (props: AppBarProps) => {
  const isLargeEnough = useMediaQuery<Theme>((theme) => theme.breakpoints.up('sm'));
  const { identity, isLoading: identityLoading } = useGetIdentity();

  return (
    <AppBar
      {...props}
      userMenu={false}
      sx={{
        backgroundImage: `linear-gradient(to bottom, ${AppColors.main}, ${AppColors.secondary})`,
      }}
    >
      <Box component='img' alt='Billing App Logo' src={Logo} />
      <Stack spacing={0} sx={{ width: '100%' }}>
        <Typography
          component='span'
          sx={{
            fontFamily: 'Roboto',
            fontStyle: 'Light',
            fontWeight: '200',
            fontSize: '24px',
            textAlign: 'right',
            lineHeight: '32px',
            letterSpacing: '0.25px',
          }}
        >
          Hi,
          <Typography
            component='span'
            sx={{
              fontFamily: 'Roboto',
              fontStyle: 'regular',
              fontWeight: '200',
              fontSize: '24px',
              textAlign: 'right',
              lineHeight: '32px',
              letterSpacing: '0.25px',
              paddingLeft: '5px',
            }}
          >
            {identityLoading ? (
              <CircularProgress color='inherit' size={24} />
            ) : (
              identity?.name
            )}
          </Typography>
        </Typography>
      </Stack>
      {isLargeEnough && <Box component='span' sx={{ flex: 1 }} />}
      <CustomUserMenu />
    </AppBar>
  );
};

export default CustomAppBar;
