import React, { useState } from 'react';

import {
  useGetList,
  useList,
  ListContextProvider,
  Pagination,
} from 'react-admin';
import ListInvoice from './ListInvoice';

type PreviewListProps = {
  month: number;
  year: number;
};

const PreviewList: React.FC<PreviewListProps> = ({ month, year }) => {
  const [page, setPage] = useState<number>(1);
  const [perPage, setPerPage] = useState<number>(10);
  const { data, total, isLoading } = useGetList('invoices', {
    pagination: { page, perPage },
    meta: {
      month,
      year,
    },
  });
  const listContext = useList({
    data,
    isLoading,
  });

  if (isLoading) {
    return <div>Loading..</div>;
  }

  return (
    <ListContextProvider
      value={{
        ...listContext,
        total: total || 0,
        setPage,
        page,
        perPage,
        setPerPage,
      }}
    >
      <ListInvoice />
      <Pagination />
    </ListContextProvider>
  );
};

export default PreviewList;
