import { Typography, Stack } from '@mui/material';
import RatesReportTable from '../../molecules/Tables/RatesReport';

const pageTitle = {
  fontWeight: 500,
  fontSize: '20px',
  color: '#f3495a',
};

const pageSubTitle = {
  fontWeight: 500,
  fontSize: '40px',
  color: 'black',
};

const RatesReport = () => (
  <Stack
    sx={{
      justifyContent: 'center',
      alignItems: 'center',
      marginTop: '64px',
      height: '-webkit-fill-available',
    }}
  >
    <Stack spacing={1} sx={{ width: 'auto', alignItems: 'center' }}>
      <Typography variant='h4' sx={pageTitle}>
        Rates Report
      </Typography>
      <Typography variant='h2' sx={pageSubTitle}>
        List of fixed rates
      </Typography>
      <RatesReportTable />
    </Stack>
  </Stack>
);

export default RatesReport;
