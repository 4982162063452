import { Typography, Stack } from '@mui/material';

const EditRates = () => (
  <Stack
    sx={{
      justifyContent: 'center',
      alignItems: 'center',
      marginTop: '64px',
      height: '-webkit-fill-available',
    }}
  >
    <Stack spacing={6} alignItems='center'>
      <Stack spacing={1} sx={{ width: 'auto' }}>
        <Typography variant='h3'>HERE THE VIEW FOR EDIT RATES</Typography>
      </Stack>
    </Stack>
  </Stack>
);

export default EditRates;
