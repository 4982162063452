import { useState } from 'react';
import RolRates from '../business_logic/repositories/rolRates';

import Config from '../utils/config';

const rolClient = new RolRates(Config.backendUrl);

const useRolesData = () => {
  const [loading, setLoading] = useState(false);
  const [hasError, setHasError] = useState(false);

  const getRolesData = async (url: string) => {
    setLoading(true);
    try {
      const data = await rolClient.getRoles(url);
      setLoading(false);
      return data;
    } catch (error) {
      setLoading(false);
      setHasError(true);
      return {
        roles: [],
      };
    }
  };

  return { getRolesData, loading, hasError };
};
export default useRolesData;
