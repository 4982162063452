import { usePermissions } from 'react-admin';
import { CircularProgress, Stack } from '@mui/material';
import ROLES from '../../utils/types/enums';
import AuthConfig from '../config';
import RolePermissions from '../../presentation/pages/Messages/RolePermissions';

type RoleBaseGuardProps = {
  children: React.ReactNode;
  allowedRoles?: ROLES[];
};

const RoleBaseGuard: React.FC<RoleBaseGuardProps> = ({
  children,
  allowedRoles,
}: RoleBaseGuardProps) => {
  const { permissions, isLoading } = usePermissions();

  if (isLoading) {
    return (
      <Stack
        sx={{
          justifyContent: 'center',
          alignItems: 'center',
          height: '100%',
        }}
      >
        <CircularProgress color='primary' size={50} />
      </Stack>
    );
  }

  if (
    !isLoading
    && allowedRoles?.some((role) => permissions.roles?.includes(role))
  ) {
    // eslint-disable-next-line react/jsx-no-useless-fragment
    return <>{children}</>;
  }

  return <RolePermissions />;
};

RoleBaseGuard.defaultProps = {
  allowedRoles: AuthConfig.allowedRoles,
};

export default RoleBaseGuard;
