import axios from 'axios';
import { GetListParams, DataProvider, GetListResult } from 'react-admin';
import Config from '../../../utils/config';
import { InvoiceList } from '../../models/invoice';

type InvoiceProviderPartial = Omit<DataProvider, 'getOne'>;

const invoiceService = axios.create({
  baseURL: Config.backendUrl,
  withCredentials: true,
});

const InvoiceProvider: InvoiceProviderPartial = {
  getList: async (resource: string, params: GetListParams) => {
    const { pagination, meta } = params;
    let result: GetListResult;
    try {
      const response = await invoiceService.get<InvoiceList>(
        `${resource}/${meta?.year}/${meta?.month}`,
        {
          params: {
            page: pagination.page,
            size: pagination.perPage,
          },
        },
      );
      const data = await response.data;

      const totalRegisters = data.total;
      const sizePerPage = data.size;

      const currentPage = data.page;
      const totalPage = totalRegisters / sizePerPage;

      result = {
        data: data.items,
        total: totalRegisters,
        pageInfo: {
          hasNextPage: currentPage < totalPage,
          hasPreviousPage: currentPage > 1,
        },
      };
      return result;
    } catch (error) {
      throw error;
    }
  },
};

export default InvoiceProvider;
