import { MenuItemLink, MenuProps, useSidebarState } from 'react-admin';

import Box from '@mui/material/Box';
import AddIcon from '@mui/icons-material/Add';
import RequestQuoteIcon from '@mui/icons-material/RequestQuote';
import PriceCheckIcon from '@mui/icons-material/PriceCheck';
import BackupRoundedIcon from '@mui/icons-material/BackupRounded';

const Menu = ({ dense = false }: MenuProps) => {
  const [open] = useSidebarState();

  return (
    <Box
      sx={{
        width: open ? 199 : 54,
        height: '100%',
        mt: 2,
        borderRight: '0.5px solid rgba(0, 0, 0, 0.1)',
        transition: (theme) => theme.transitions.create('width', {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.leavingScreen,
        }),
      }}
    >
      <MenuItemLink
        to='/'
        state={{ _scrollToTop: true }}
        primaryText='Generate Report'
        leftIcon={<AddIcon sx={{ my: 1 }} />}
        dense={dense}
      />
      <MenuItemLink
        to='/manualReport'
        state={{ _scrollToTop: true }}
        primaryText='Manual Report'
        leftIcon={<BackupRoundedIcon sx={{ my: 1 }} />}
        dense={dense}
      />
      <MenuItemLink
        to='/ratesReport'
        state={{ _scrollToTop: true }}
        primaryText='Rates Report'
        leftIcon={<RequestQuoteIcon sx={{ my: 1 }} />}
        dense={dense}
      />
      <MenuItemLink
        to='/editRates'
        state={{ _scrollToTop: true }}
        primaryText='Edit Rates'
        leftIcon={<PriceCheckIcon sx={{ my: 1 }} />}
        dense={dense}
      />
    </Box>
  );
};

export default Menu;
