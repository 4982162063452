import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import Typography from '@mui/material/Typography';
import Button, { ButtonProps } from '@mui/material/Button';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import { CircularProgress } from '@mui/material';
import React, { PropsWithChildren } from 'react';

const CustomModal: React.FC<PropsWithChildren<CustomModalProps>> = ({
  open,
  handleClose,
  title,
  description,
  confirmButtonText,
  handleConfirm,
  cancelButtonText,
  handleCancel,
  confirmButtonIcon,
  children,
  width,
  requiredIndication,
  cancelButtonColor,
  confirmButtonColor,
  disabledConfirmButton,
  isLoading,
  variant,
}) => (
  <Modal
    open={open}
    onClose={(_, reason) => {
      if (isLoading && reason === 'backdropClick') return;
      handleClose();
    }}
    aria-labelledby='modal-modal-title'
    aria-describedby='modal-modal-description'
  >
    <Box
      sx={{
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width,
        bgcolor: 'background.paper',
        borderRadius: '5px',
        boxShadow: 24,
        p: 4,
      }}
    >
      <h3>{title}</h3>
      <Typography id='modal-modal-description' sx={{ mt: 2 }} component='span'>
        {description}
        <Typography variant='body2' sx={{ mt: 2 }}>
          {requiredIndication}
        </Typography>
        {children}
        <div
          style={{
            display: 'flex',
            justifyContent: 'flex-end',
            alignItems: 'center',
            marginTop: '10px',
          }}
        >
          <Button
            variant={variant}
            onClick={handleCancel}
            color={cancelButtonColor}
            startIcon={<HighlightOffIcon />}
            size='small'
            style={{ marginRight: '5px' }}
          >
            {cancelButtonText}
          </Button>
          <Button
            variant={variant}
            disabled={disabledConfirmButton}
            color={confirmButtonColor}
            onClick={handleConfirm}
            startIcon={confirmButtonIcon}
            size='small'
          >
            {isLoading && <CircularProgress size='1em' />}
            {confirmButtonText}
          </Button>
        </div>
      </Typography>
    </Box>
  </Modal>
);

export default CustomModal;

export type CustomModalProps = {
  open: boolean;
  handleClose: () => void;
  title: string;
  description: string;
  confirmButtonText: string;
  handleConfirm: () => void;
  cancelButtonText: string;
  handleCancel: () => void;
  confirmButtonIcon: React.ReactNode;
  requiredIndication?: string;
  width?: string | number;
  cancelButtonColor?: ButtonProps['color'];
  confirmButtonColor?: ButtonProps['color'];
  disabledConfirmButton?: boolean;
  isLoading?: boolean;
  variant?: ButtonProps['variant'];
};

CustomModal.defaultProps = {
  width: 400,
  requiredIndication: '',
  cancelButtonColor: 'error',
  confirmButtonColor: 'success',
  disabledConfirmButton: false,
  isLoading: false,
  variant: 'contained',
};
