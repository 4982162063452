import React, { useState } from 'react';
import {
  Datagrid,
  TextField,
  useRecordContext,
  useNotify,
  usePermissions,
  PublicFieldProps,
} from 'react-admin';
import ContentCreate from '@mui/icons-material/Create';
import { Box, Button } from '@mui/material';
import SaveIcon from '@mui/icons-material/Save';
import useForm from '../../../../hooks/useForm';
import CustomModal from '../../CustomModal';
import EditFixRateForm from '../../forms/EditFixRateForm';
import { EditRateForm, ROLES } from '../../../../utils/types';
import {
  RoleRateItem,
  RoleRateList,
} from '../../../../business_logic/models/roleRateEntry';

const dataGridStyles = {
  '& .RaDatagrid-rowCell': {},
  '& .column-fixRate': {
    textAlign: 'center',
  },
  '& .column-role': {
    padding: '0 0 0 3rem',
  },
  '& .RaDatagrid-headerCell': {
    padding: '0 5rem',
    height: '2.5rem',
  },
};

const RowActions: React.FC<RowActionsProps> = ({
  setOpenModal,
  setFormValues,
}) => {
  const record = useRecordContext();

  return (
    <Box sx={{ textAlign: 'center', justifyContent: 'center' }}>
      <Button
        onClick={() => {
          const { id, role, fixRate } = record;
          setFormValues({
            id,
            role,
            fixRate,
          });
          setOpenModal(true);
        }}
        aria-label='edit'
      >
        <ContentCreate />
      </Button>
    </Box>
  );
};

type ListRatesProps = {
  setData: React.Dispatch<React.SetStateAction<RoleRateList>>;
};

const ListRates: React.FC<ListRatesProps> = ({ setData }) => {
  const notify = useNotify();
  const { permissions, isLoading: isLoadingPermissions } = usePermissions();
  const defaultRole: EditRateForm = {
    id: '',
    role: '',
    fixRate: NaN,
  };
  const [openEditModal, setOpenEditModal] = useState(false);
  const {
    formValues: roleFormValues,
    handleInputChange: handleRolesInputChange,
    reset,
    setFormValues,
  } = useForm(defaultRole);

  const updateRole = () => {
    const newRoleData: RoleRateItem = roleFormValues;
    setData((roleRateList) => {
      roleRateList.splice(
        roleRateList.findIndex((role) => role.id === newRoleData.id),
        1,
        newRoleData,
      );
      return [...roleRateList];
    });
    reset();
    setOpenEditModal(false);
    notify('Fixed rate edited successfully', { type: 'success' });
  };

  const editUserModalProps = {
    title: 'Edit Fixed Rate',
    description: `Please, change the fixed rate for the role: ${roleFormValues?.role}`,
    confirmButtonText: 'Save',
    cancelButtonText: 'Cancel',
    confirmButtonIcon: <SaveIcon />,
  };

  const isEditAvailable = permissions?.roles?.includes(ROLES.fixedRatesEditor);

  const isValidInputFields = () => roleFormValues.id
    && roleFormValues.fixRate > 0
    && !Number.isNaN(roleFormValues.fixRate);

  return (
    <>
      <Datagrid
        bulkActionButtons={false}
        sx={dataGridStyles}
        size='small'
        isLoading={isLoadingPermissions}
      >
        <TextField source='role' label='Role' />
        <TextField source='fixRate' label='Fixed Rate' />
        {isEditAvailable && (
          <RowActions
            sortable={false}
            source='Actions'
            setOpenModal={setOpenEditModal}
            setFormValues={setFormValues}
          />
        )}
      </Datagrid>
      {isEditAvailable && (
        <CustomModal
          {...editUserModalProps}
          open={openEditModal}
          handleClose={() => setOpenEditModal(false)}
          handleConfirm={updateRole}
          handleCancel={() => setOpenEditModal(false)}
          disabledConfirmButton={!isValidInputFields()}
          variant='outlined'
          confirmButtonColor='primary'
        >
          <EditFixRateForm
            formValues={roleFormValues}
            handleInputChange={handleRolesInputChange}
          />
        </CustomModal>
      )}
    </>
  );
};

export default ListRates;

type RowActionsProps<FormType = EditRateForm> = {
  setOpenModal: React.Dispatch<React.SetStateAction<boolean>>;
  setFormValues: React.Dispatch<React.SetStateAction<FormType>>;
} & PublicFieldProps;
