import { useState } from 'react';

import { Button, Typography, Stack } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import DatePicker from '../../molecules/DatePicker';

import useDownloadFile from '../../../hooks/useDownloadFile';
import { AppColors } from '../../../AppTheme';
import PreviewList from '../../molecules/Tables/Invoice';
import formatDate from '../../../business_logic/utils/formatDate';

const getMonthAndYear = (newDate: Date) => {
  const [monthOfReport, yearOfReport] = formatDate(newDate).split('-').slice(1);
  return [Number(monthOfReport), Number([yearOfReport])];
};

const Landing = () => {
  const [date, setDate] = useState<Date | null>(new Date());
  const [month, setMonth] = useState<number>(getMonthAndYear(new Date())[0]);
  const [year, setYear] = useState<number>(getMonthAndYear(new Date())[1]);

  const { getFile, loading } = useDownloadFile();

  const onChange = (newDate: Date | null) => {
    if (newDate) {
      const [monthOfReport, yearOfReport] = getMonthAndYear(newDate);
      setDate(newDate);
      setMonth(monthOfReport);
      setYear(yearOfReport);
    }
  };

  const onDownload = () => {
    if (date) {
      const url = `report/${year}/${Number(month)}`;
      const fileName = `report_${year}_${month}.xlsx`;
      getFile(url, fileName);
    }
  };

  return (
    <Stack
      sx={{
        justifyContent: 'center',
        alignItems: 'center',
        marginTop: '64px',
        height: '-webkit-fill-available',
      }}
    >
      <Stack spacing={6} alignItems='center'>
        <Stack spacing={1} sx={{ width: 'auto' }}>
          <Typography variant='h3'>Welcome to Billing</Typography>
          <Typography
            sx={{ color: AppColors.main, textAlign: 'start' }}
            variant='subtitle1'
          >
            Latest Notion data update:&nbsp;
            {formatDate(new Date())}
          </Typography>
        </Stack>
        <Stack
          direction='row'
          spacing={7}
          sx={{
            justifyContent: 'center',
          }}
        >
          <DatePicker date={date} handleChange={onChange} />
          <Button
            variant='contained'
            onClick={onDownload}
            sx={{ width: '100px' }}
          >
            {loading ? (
              <CircularProgress color='inherit' size={24} />
            ) : (
              'DOWNLOAD'
            )}
          </Button>
        </Stack>
        <PreviewList month={month} year={year} />
      </Stack>
    </Stack>
  );
};

export default Landing;
