import axios, { AxiosInstance, AxiosRequestConfig } from 'axios';
import Base from './base';

class RolRates implements Base {
  client: AxiosInstance;

  constructor(baseUrl: string) {
    this.client = axios.create({
      baseURL: baseUrl,
      withCredentials: true,
    });
  }

  static getConfigGetRoles(): AxiosRequestConfig {
    const axiosConfig: AxiosRequestConfig = {
      headers: {
        'Access-Control-Allow-Origin': '*',
        'Content-Type': 'application/json',
      },
    };

    return axiosConfig;
  }

  async getRoles(url: string) {
    const config = RolRates.getConfigGetRoles();
    try {
      const { data } = await this.client.get(url, config);
      return data;
    } catch (error) {
      throw error;
    }
  }
}
export default RolRates;
