import axios, { AxiosInstance, AxiosRequestConfig } from 'axios';
import Base from './base';

class Report implements Base {
  client: AxiosInstance;

  constructor(baseUrl: string) {
    this.client = axios.create({
      baseURL: baseUrl,
      withCredentials: true,
    });
  }

  static getConfigToDownloadReport(fileName: string) {
    const axiosConfig: AxiosRequestConfig = {
      headers: {
        'Access-Control-Allow-Origin': '*',
        'Content-Disposition': `attachment; filename=${fileName}`,
        'Content-Type': 'multipart/form-data',
      },
      responseType: 'blob',
    };

    return axiosConfig;
  }

  async getReport(url: string, fileName: string) {
    const config = Report.getConfigToDownloadReport(fileName);
    try {
      const response = await this.client.get(url, config);
      const data = await response.data;
      return data;
    } catch (error) {
      throw error;
    }
  }

  async getManualReport(url: string, fileName: string, files: FormData) {
    const config = Report.getConfigToDownloadReport(fileName);
    try {
      const response = await this.client.post(url, files, config);
      const data = await response.data;
      return data;
    } catch (error) {
      throw error;
    }
  }
}

export default Report;
