import React from 'react';
import { DatePicker as DatePickerMUI } from '@mui/x-date-pickers';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { TextField } from '@mui/material';

import { AppColors } from '../../../AppTheme';
import Config from '../../../utils/config';

type DatePickerProps = {
  date: Date | null;
  handleChange: (date: Date | null) => void;
};

const DatePicker: React.FC<DatePickerProps> = ({ date, handleChange }) => (
  <LocalizationProvider dateAdapter={AdapterDateFns}>
    <DatePickerMUI
      disableMaskedInput
      openTo='month'
      views={['year', 'month']}
      minDate={new Date(Config.dataPickerMinDate)}
      maxDate={new Date()}
      components={{
        OpenPickerIcon: ArrowDropDownIcon,
      }}
      inputFormat='MMM - yyyy'
      value={date}
      renderInput={(params) => (
        <TextField
          onKeyDown={(e) => {
            e.preventDefault();
          }}
          sx={{
            width: '200px',
            textTransform: 'uppercase',
          }}
          variant='standard'
          {...params}
        />
      )}
      PopperProps={{
        sx: {
          '& .MuiMonthPicker-root > .MuiTypography-subtitle1, & .MuiMonthPicker-root > button:disabled':
            {
              color: AppColors.main,
            },
          '& button': {
            textTransform: 'uppercase',
          },
        },
      }}
      onChange={handleChange}
    />
  </LocalizationProvider>
);

export default DatePicker;
