import React from 'react';
import { Box, Typography } from '@mui/material';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import { useDropzone, FileRejection } from 'react-dropzone';

import { AppColors } from '../../../AppTheme';

type DropzoneProps = {
  title: string;
  maxFiles: number;
  error: React.Dispatch<React.SetStateAction<string>>;
  file: React.Dispatch<React.SetStateAction<File | undefined>>;
};

const Dropzone: React.FC<DropzoneProps> = ({
  title,
  maxFiles,
  error,
  file,
}) => {
  const { getRootProps, getInputProps } = useDropzone({
    maxFiles,
    accept: {
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': [
        '.xls',
        '.xlsx',
      ],
    },
    onDropRejected: (fileRejections: FileRejection[]) => {
      if (fileRejections.length > maxFiles) {
        error(`Max files to select is ${maxFiles}`);
      } else {
        error('Wrong file type please select again');
      }
      file(undefined);
    },
    onDropAccepted: (acceptedFiles: File[]) => {
      error('');
      file(acceptedFiles[0]);
    },
  });

  return (
    <Box
      {...getRootProps({ className: 'dropzone' })}
      sx={{
        height: '170px',
        minWidth: '200px',
        width: '100%',
        border: 'dashed 2px rgba(1,1,1,0.2)',
        backgroundColor: 'hsl(357deg 22% 97%)',
        borderRadius: '15px',
        boxShadow: '3px 3px 4px hsl(0deg 2% 74% / 20%)',
        cursor: 'pointer',
        ':hover': {
          backgroundColor: AppColors.hover,
        },
      }}
    >
      <input {...getInputProps()} data-testid={`input-${title}`} />
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          flexDirection: 'column',
          height: '100%',
        }}
      >
        <CloudUploadIcon
          sx={{ transform: 'scale(3)', marginBottom: '20px' }}
          color='primary'
        />
        <Typography variant='subtitle1'>
          {title}
          <span style={{ color: 'red' }}>&#42;</span>
        </Typography>
        <Typography variant='subtitle2' sx={{ textAlign: 'center' }}>
          Drag and drop or browse
          <br />
          to choose a file
        </Typography>
      </Box>
    </Box>
  );
};

export default Dropzone;
