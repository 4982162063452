import { useState } from 'react';
import { TargetType, UseForm } from '../utils/types';

const useForm = <FormType>(
  initialState = {} as FormType,
): UseForm<FormType> => {
  const [formValues, setFormValues] = useState(initialState);

  const reset = () => {
    setFormValues(initialState);
  };

  const handleInputChange = ({
    target,
  }:
  | React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  | TargetType<FormType>) => {
    setFormValues({
      ...formValues,
      [target.name]: target.value,
    });
  };

  return {
    formValues,
    handleInputChange,
    reset,
    setFormValues,
  };
};

export default useForm;
