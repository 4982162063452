/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { ListContextProvider, useList } from 'react-admin';
import CircularProgress from '@mui/material/CircularProgress';
import { Typography } from '@mui/material';

import {
  RoleRateEntry,
  RoleRateList,
} from '../../../../business_logic/models/roleRateEntry';
import useRolesData from '../../../../hooks/useRolesData';
import ListRates from './ListRates';

const URL = '/mock-api/client';

const RatesReportTable: React.FC = () => {
  const { loading, hasError, getRolesData } = useRolesData();
  const [data, setRolesData] = useState<RoleRateList>([]);
  const listContext = useList({ data });

  useEffect(() => {
    async function fetchData() {
      const fetchedRoles = await getRolesData(URL);
      const newData = fetchedRoles.roles.map((role: RoleRateEntry) => ({
        id: role.id,
        role: role.name,
        fixRate: 305,
      }));
      setRolesData(newData);
    }
    fetchData();
  }, []);

  if (loading) {
    return <CircularProgress color='inherit' size={24} />;
  }
  if (hasError) {
    return <Typography>Error get data from API</Typography>;
  }

  return (
    <ListContextProvider
      value={{
        ...listContext,
      }}
    >
      <ListRates setData={setRolesData} />
    </ListContextProvider>
  );
};

export default RatesReportTable;
